<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
					color="primary"
					class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.ripener_form_modal.openModal()"
          >
            <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
            <span>Crear Madurador</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
      :headers="tableColumns"
			:page="current_page"
      :items="ripenerListTable"
      :server-items-length="totalRipenerListTable"
      :loading="loading"
      @update:page="changingRipenerPage($event)"
      @update:items-per-page="changingRipenerItemsPerPage($event)"
			:footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      >

        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Maduradores</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <template #[`item.name`]="{item}">
          <v-btn
          class="no-uppercase ripener-name-container pa-1 mr-2"
          text
          color="primary"
          min-width="0"
          max-height="28px"
          @click="$refs.ripener_details_modal.openModal(item.id)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!--Date-->
        <template #[`item.created_at`]="{item}">
          <span class="date-container">{{item.created_at.slice(0, 10)}}</span>
        </template>

        <!--Status-->
        <template #[`item.status`]="{item}">
          <v-btn
						class="no-uppercase status-container pa-2"
						depressed
            :color="statusColorParser(item.status)"
            max-height="26px"
            min-width="0"
					>
					<span>{{statusParser[item.status]}}</span>
					</v-btn>
        </template>

        <!--Delete-->
        <template #[`item.actions`]="{item}">
          <v-item-group class="v-btn-group">
            <v-btn
            class="grouped-btn px-3"
            depressed
            color="primary"
            min-width="0"
            v-if="item.status === 0 || item.status === 1 || item.status == 2"
            @click="openPauseModal(item.id)"
            >
              <v-icon>
                {{icons.mdiPause}}
              </v-icon>
            </v-btn>
            <v-btn
            class="grouped-btn px-3"
            depressed
            color="success"
            min-width="0"
            v-if="item.status === 3"
            @click="openPlayModal(item.id)"
            >
              <v-icon>
                {{icons.mdiPlay}}
              </v-icon>
            </v-btn>
            <v-btn
            class="grouped-btn px-3"
            depressed
            color="error"
            min-width="0"
            @click="$refs.delete_ripener_modal.open(item)"
            >
              <v-icon>
                {{icons.mdiDeleteOutline}}
              </v-icon>
            </v-btn>
          </v-item-group>
				</template>
      </v-data-table>
    </v-card>
    <RipenerFormModal ref="ripener_form_modal" @reload-ripener-list="getRipenerList()"/>
    <DeleteRipenerModal ref="delete_ripener_modal" @reload-ripener-list="getRipenerList()"/>
    <RipenerDetailsModal ref="ripener_details_modal" />

    <template>
      <v-dialog
      v-model="showingPauseRipenerModal"
      width="auto"
      persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            ¿Está seguro de querer pausar el madurador?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn
            color="primary"
            text
            @click="closePauseModal()"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="error"
            @click="pauseRipener(changeRipenerStatusId)"
            >
              Pausar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-dialog
      v-model="showingPlayRipenerModal"
      width="auto"
      persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            ¿El madurador se iniciará en los horarios establecidos. Está seguro de iniciarlo?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn
            color="primary"
            text
            @click="closePlayModal()"
            >
              CANCELAR
            </v-btn>
            <v-btn
              color="success"
              @click="playRipener(changeRipenerStatusId)"
              >
                Iniciar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {ref} from '@vue/composition-api';
import RipenerFormModal from '@/components/Ripener/RipenerFormModal'
import DeleteRipenerModal from '@/components/Ripener/DeleteRipenerModal'
import RipenerDetailsModal from '@/components/Ripener/RipenerDetailsModal'
import {
  mdiPlusCircleOutline,
  mdiFilePlusOutline,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiPause,
  mdiPlay
} from '@mdi/js'

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      stopedColor: 'rgb(255, 76, 81)',
      pendingColor: 'rgb(24, 103, 192)',
      creatingColor: "rgb(146, 149, 143)",
      completedColor: 'rgb(86, 202, 0)',
      inProgressColor: 'rgb(145, 85, 253)',
      pausedColor: 'rgb(255, 180, 0)',
      showingPauseRipenerModal: false,
      changeRipenerStatusId: null,
      showingPlayRipenerModal: false,
      ripenerToPauseInfo: null,
    }
  },
  components: {
    RipenerFormModal,
    DeleteRipenerModal,
    RipenerDetailsModal
},
  methods: {
    async getRipenerList() {
      this.loading = true
      try {
        let query = {
          page_size: this.page_size,
          page: this.current_page,
        }
        const response = await this.$api.getRipenerList(query)
        this.totalRipenerListTable = response.count
        this.ripenerListTable = response.results
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
        this.resetTimer()
      }
    },
    changingRipenerPage(e) {
      this.current_page = e
      this.getRipenerList()
    },

    changingRipenerItemsPerPage(e) {
      this.page_size = e
      this.getRipenerList()
    },

    async getRipener(id) {
      try {
        const response = await this.$api.getRipener(id)
        console.log(response)
      } catch(e) {
        console.log(e)
      }
    },

    async pauseRipener(id) {
      const data = {
        status: 3
      }
      const response = await this.$api.updateRipenerStatus(id, data)
      this.getRipenerList()
      this.closePauseModal()
    },

    async playRipener(id) {
      const data = {
          status: 0
      }
      const response = await this.$api.updateRipenerStatus(id, data)
      this.getRipenerList()
      this.closePlayModal()
    },

    async openPauseModal(id){
      this.changeRipenerStatusId = id
      this.showingPauseRipenerModal = true
    },

    openPlayModal(id){
      this.changeRipenerStatusId = id
      this.showingPlayRipenerModal = true
    },

    closePauseModal(){
      this.changeRipenerStatusId = null
      this.showingPauseRipenerModal = false
      this.ripenerToPauseInfo = null
    },
    closePlayModal(){
      this.changeRipenerStatusId = null
      this.showingPlayRipenerModal = false
    },

    statusColorParser(status) {
      if(status === 0) return this.pendingColor
      if(status === 1) return this.inProgressColor
      if(status === 2) return this.pausedColor
      if(status === 3) return this.stopedColor
      if(status === 4) return this.completedColor
      if(status === 5) return this.creatingColor;
    },

    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getRipenerList()
      }, 1000 * 45)
    },
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getRipenerList()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    statusParser() {
      const parser = {
        0: 'Procesando',
        1: 'Enviando',
        2: 'Pausado',
        3: 'Detenido',
        4: 'Finalizado',
        5: 'En creación'
      }
      return parser
    }
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getRipenerList()
      }
    }
  },
  setup() {
    const totalRipenerListTable = ref(1);
    const ripenerListTable = ref([]);

    const tableColumns = [
    { text: 'Titulo', value: 'name', align: 'center', sortable: false },
    { text: 'Fecha', value: 'created_at', align: 'center', sortable: false },
    { text: 'Estado', value: 'status', align: 'center', sortable: false },
    { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
    ];

    const loading = ref(false);
    return {
      loading,
      tableColumns,
      totalRipenerListTable,
      ripenerListTable,
      icons: {
        mdiPlusCircleOutline,
        mdiFilePlusOutline,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiPause,
        mdiPlay
      }
    }
  }
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.ripener-name-container span {
  font-weight: 600;
  font-size: 15px;
}

.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.status-container {
  color: white !important;
  font-size: 13px;
}

.grouped-btn {
  border: none;
  border-radius: 0px
}

.grouped-btn:first-child{
  border-radius: 10px 0 0 10px;
}

.grouped-btn:last-child{
  border-radius: 0 10px 10px 0;
}
</style>
