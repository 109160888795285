<template>
  <div>
      <v-card>
          <v-card-title>Madurador</v-card-title>
          <RipenerList></RipenerList>
      </v-card>
  </div>
</template>

<script>
import RipenerList from '@/components/Ripener/RipenerList'

export default {
  components: {
    RipenerList
  }
}

</script>