<template>
  <v-dialog
    v-model="showingDeleteRipenerModal"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        ¿Estás seguro de eliminar el modelo de mensaje '{{ ripener.name }}'?
      </v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <span 
        class="error--text error-msg"
        v-if="error_msg"
        >{{ error_msg }}</span>
        <v-spacer></v-spacer>

        <v-btn 
        color="primary" 
        text 
        @click="close()"
        >
            CANCELAR
        </v-btn>         
        <v-btn
        color="error"
        @click="deleteRipener()"
        >
            Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
      return {
          showingDeleteRipenerModal: false,
          ripener: {},
          error_msg: null
      }
  },

  methods: {
      open(ripener) {
        this.showingDeleteRipenerModal = true
        this.ripener = ripener
      },

      close() {
        this.error_msg = null
        this.showingDeleteRipenerModal = false
      },

      async deleteRipener() {
          try {
              const response = await this.$api.deleteRipener(this.ripener.id)

              this.$emit('reload-ripener-list')
              this.close()
          } catch(e) {
              console.log(e)
              if (e?.response?.status === 403) {
                console.log(e.response.data)
                this.error_msg = e?.response?.data?.message
              }
          }
      }
  }
}
</script>

<style scoped>
.error_msg{
    color: red;
    margin-left: 25px;
}
</style>