import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.showingDeleteRipenerModal),callback:function ($$v) {_vm.showingDeleteRipenerModal=$$v},expression:"showingDeleteRipenerModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" ¿Estás seguro de eliminar el modelo de mensaje '"+_vm._s(_vm.ripener.name)+"'? ")]),_c(VDivider),_c(VCardActions,[(_vm.error_msg)?_c('span',{staticClass:"error--text error-msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteRipener()}}},[_vm._v(" Eliminar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }